import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/Layout'
import ArrowDownRight from '../assets/svg/arrow-down-right.svg'
import { pageLink } from '../utils'


import './developers.scss'


const DevelopersPage = () => {
  return (
    <Layout
      title="Keymaker | Developers"
      pageClassName="page-developers"
    >
      <section className="page-developers__hero">
        <div className="page-developers__hero__container">
          <h1 className="page-title">Developer Resources</h1>
          <p>The World’s First Search Engine for the Metaverse</p>
          <Link className="button" to={ pageLink.home } >MHTTP SPEC</Link>
          <Link className="button" to={ pageLink.github }><ArrowDownRight className="icon" />GITHUB</Link>
        </div>
      </section>
    </Layout>
  )
}

export default DevelopersPage